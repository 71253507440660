.header {
  display: flex;
  background-color: #fff;
  height: 70px;
  align-items: center;
  position: sticky;
  top: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 100;
  justify-content: space-between;
  @media (max-width: 768px) {
    overflow: hidden;
  }
}
#basic-nav-dropdown {
  letter-spacing: 1px;
  font-size: 14px;
}
.menu5.active .option {
  color: rgb(59 130 246);
  text-decoration: underline;
}
.active {
  color: rgb(59, 130, 246);
}

.animation_header {
  display: block;
  transition: all 1s;
  position: relative;
  z-index: 1;
}

.animation_header:hover {
  color: white;
}

.animation_header::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgb(221, 220, 220);
  color: whitesmoke;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 11px;
  border-radius: 8px;
}

.animation_header:hover::after {
  opacity: 1;
}

@keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  transform-origin: top center;
  animation: scaleZ 1200ms ease-in-out forwards;
}

.grow {
  transition: all 0.3s ease-in-out;
}
.grow:hover {
  transform: scale(1.5);
}
.dropdown-menu {
  margin-top: -3px;
}
.logo {
  display: flex;
  box-sizing: border-box;
}

.header_logo {
  display: flex;
  align-items: center;
  max-height: 50px;
  width: 140px;
  object-fit: contain;
  margin-left: 0px;
  @media (max-width: 768px) {
    margin-left: 15px;
    width: 77px;
  }
}
.header_nav {
  display: flex;
  justify-content: space-evenly;
  margin-right: 48px;
}

.header_option {
  display: flex;
  padding: 18px;
}

.option_link {
  text-decoration: none;
}

.option {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-shadow: 0 0px 0 rgba(255, 255, 255, 0.25);
  text-decoration: none;
}
.d-flex li {
  padding: 5px;
}
.social-icon-item {
  border-left: 1px solid #c4c4c4;
}
@media (max-width: 768px) {
  .social-icon-item {
    margin-right: 0;
  }
  .align-items-center {
    align-items: center !important;
    margin-left: -142px;
  }
}
.navbar-nav .dropdown-menu {
  margin-left: -332px;
  padding-right: 32px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.social .icon {
  margin-top: 23px;
}
#basic-nav-dropdown:hover {
  color: rgb(59 130 246);
}

#basic-nav-dropdown p {
  font-weight: 600;
  margin-right: 4px;
}
.header h3 {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 51px;
}
@media (max-width: 767px) {
  .header h3 {
    display: contents;
  }
  .hidden-on-tablet {
    display: none;
  }
  .animation_header::after {
    width: 130px;
  }
}
#navbar .small_div {
  width: 18%;
}
#navbar .box-icon {
  color: dodgerblue;
  font-size: 22px;
}
#navbar .small-text {
  font-size: 12px;
  display: block;
  color: gray;
}
@media (max-width: 768px) {
  .nav-dropdown-container {
    display: block;
    flex-direction: column;
  }
  .navbar-nav .dropdown-menu {
    width: 100%;
    margin-left: -9px;
  }
  .navbar-nav .dropdown-item {
    width: 100%;
    text-align: center;
    margin-bottom: -8px;
    margin-left: -12px;
  }
  .small-text p {
    font-size: 11px;
  }
  .text-sm {
    font-size: 11px;
  }
  .small-div {
    font-size: 17px;
    margin-left: 131px;
  }
  .align-items-center {
    margin-left: -12px;
  }
  .social .icon {
    margin-left: 6px;
    margin-top: 7px;
  }
  body {
    overflow-x: hidden;
  }
}
.nav-dropdown-container-grid .grid-item:hover {
  background-color: transparent !important;
}
.nav-dropdown-container-grid .grid-item:focus {
  background-color: transparent !important;
  outline: none;
  transform: none !important;
}
.menu5 .menus {
  color: #515151;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 4px;
  font-size: 14px;
  letter-spacing: 1px;
  font-style: normal;
}
.menu5 .menus,
.menu5 .menus:after,
.menu5 .menus:before {
  transition: all 0.5s;
}
.menu5 .menus:hover {
  color: rgb(59 130 246);
}
.menu5 .menus {
  position: relative;
}
.menu5 .menus:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: "-";
  color: transparent;
  background: rgb(59 130 246);
  height: 2px;
}
.menu5 .menus:hover:after {
  width: 100%;
}
.offcanvas-header .btn-close {
  margin-left: 118px;
}
@media (max-width: 767px) {
  #navbar p {
    font-size: 16px;
  }
  .menu5 .menus {
    color: #000000;
  }
  .menu5 .menus:hover:after {
    width: 0%;
  }
  .menu5 .menus:hover {
    color: black;
  }
  .menu5 .menus:after {
    color: rgb(255, 255, 255);
  }
  .navbar-nav .dropdown-menu {
    transform-origin: top center;
    animation: scaleZ 1200ms ease-in-out forwards;
  }
}
@media (min-width: 769px) {
  .nav-dropdown-container-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
  .animation_header::after {
    width: 200px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: -3px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .icon {
    margin-left: 5px;
  }
  .header_logo {
    margin-top: -6px;
  }
  .header h3 {
    padding: 15px;
  }
  .social .icon {
    margin-top: 7px;
  }
  .hidden-on-tablet {
    display: none;
  }
  .nav-dropdown-container-grid {
    display: contents;
    grid-template-columns: repeat(4, 1fr);
  }
  .navbar-nav .dropdown-menu {
    margin-left: 15px;
  }
  .navbar-nav .dropdown-menu h2 {
    margin-top: 14px;
  }
  .offcanvas-header .btn-close {
    font-size: 22px;
    margin-left: 301px;
  }
  .navbar-nav .dropdown-menu {
    transform-origin: top center;
    animation: scaleZ 1200ms ease-in-out forwards;
  }
  .menu5 .menus:after {
    color: transparent;
    background-color: transparent;
  }
}
