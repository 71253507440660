.home-client {
  padding: 10px 0;
}

.home-client .client-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.home-client .client-logo img {
  padding: 0px;
  max-width: 80%;
  transition: 0.3s;
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .home-client .client-logo img {
    padding: 6px;
  }
  .home-client .client-logo img {
    max-width: 100%;
  }
}
