.tech_home {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-top: 0.3px solid rgba(180, 180, 180, 0.381);
  background: #f8f9fa;
}
.paragraph {
  font-size: 14px;
  text-align: center;
  display: block;
  margin-bottom: 35px;
}

.heading_title h1 {
  margin-top: 45px;
  margin-bottom: 20px;
  font-family: "Roboto" sans-serif;
  font-size: 31px;
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.Techno_Comp {
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  margin-right: 120px;
  padding-bottom: 90px;
  @media (max-width: 768px) {
    margin-left: 88px;
    margin-right: 88px;
  }
  @media (max-width: 576px) {
    margin-left: 38px;
    margin-right: 38px;
  }
}

.tech_home_comp {
  display: flex;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

.comp_row1 {
  display: flex;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  .heading_title h1 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .heading_title h1 {
    font-size: 25px;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  .heading_title h1 {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .paragraph {
    font-size: 15px;
    text-align: justify;
    margin-left: 24px;
    margin-right: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .comp_row {
    display: flex;
    flex-direction: row;
  }
  .component2 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .Techno_Comp {
    margin-left: 28px;
    margin-right: 19px;
  }
  .paragraph {
    margin-left: 30px;
    margin-right: 34px;
    text-align: justify;
  }
}
