.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
  overflow: hidden;
}

.carousel_fallback {
  display: flex;
  height: 500px;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: 150px;
  }
}

.callback_msg {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(231, 231, 231);
  background-color: rgb(255, 255, 255);
  height: 77px;
  width: 100px;
  z-index: 100;
  box-shadow: 0 10px 15px 0 #0000002f, 0 1px 6px #0000000a;
  border-radius: 10px;
  @media (max-width: 768px) {
    height: 55px;
    width: 90px;
  }
}
.test_text {
  margin-top: 45px;
  margin-bottom: 20px;
  font-family: "Roboto" sans-serif;
  font-size: 31px;
  font-weight: 700;
}
#testimonial_section {
  background-image: url(../assets/back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 12px;
  height: 370px;
  margin-top: 15px;
}
.custom-section-padding {
  padding-top: 50px;
  padding-bottom: 50px;
}

.staff-augmentation-testimonial {
  background-position: center;
  background-size: cover;
}

#testimonial_section h1 {
  margin-bottom: -22px;
  font-size: 22px;
}
#testimonial_section p {
  margin-bottom: 36px;
  font-size: 16px;
  margin-top: 5px;
}
.slick-slider {
  width: 80%;
  margin: 0 auto;
}
.slick-prev,
.slick-next {
  font-size: 24px;
}
.custom-testimonial-style-1 {
  border-left: 2px solid #e84545;
  padding-left: 20px;
  margin-bottom: 30px;
}
.slick-dots {
  bottom: 1px;
}
.custom-testimonial-style-1 blockquote {
  font-size: 18px;
  line-height: 1.6;
}
#testimonial_section .row {
  margin-top: -12px;
}
.testimonial-author {
  margin-top: -46px;
}

.text-color-primary {
  color: gray;
}
.testimonial-author strong {
  font-size: 15px;
}
.quotesImg {
  font-size: 50px;
  border: none;
  margin-top: 9px;
  border-radius: 0;
  box-shadow: none;
  color: #3c92da;
}
.quotes {
  color: #3c92da;
  font-size: 50px;
  border: none;
  float: right;
  margin-top: -25px;
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  #testimonial_section p {
    font-size: 14px;
  }
  .test_text {
    font-size: 20px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .test_text {
    font-size: 25px;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  .test_text {
    margin-top: 20px;
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .custom-section-padding {
    padding: 0px;
  }
  .image img {
    margin-left: 64px;
  }
  .caro {
    height: 610px;
  }
  .slide img {
    height: 217px;
  }
  #testimonial_section {
    height: 771px;
  }
  .quotes {
    font-size: 33px;
  }
  .quotesImg {
    font-size: 33px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .image img {
    margin-left: 196px;
  }
  #testimonial_section {
    height: auto;
  }
  .custom-section-padding {
    padding: 0px;
  }
}
