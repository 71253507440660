.footer {
  display: flex;
  margin-top: 22px;
  justify-content: space-between;
  justify-content: space-between;
  background: black;
  width: 100%;
  color: #fff;
  text-align: start;
  padding: 75px 50px 35px 50px;
  font-size: 14px;
  font-family: "Open Sans" sans-serif;
  padding: 30px 0 10px 0;
  background: #1e1e1e url(../../assets/footer-bg.png) no-repeat right top;
  background-size: contain;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}
.footer h4:hover{
  color: rgb(106, 180, 255);
}
.row2 a , .row3 a {
  text-decoration: none;
  color: #fff !important;
  padding: 4px 0px;
}
.row2 span:hover , .row3 span:hover{
  color: rgb(103 232 249) !important;
  cursor: pointer;
}
.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.5);
}
.footer span {
  padding: 4px 0;
}
.row5 {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  margin-left: 31px;
}
.row5 span:hover{
  color: rgb(103 232 249) !important;
}
.row2 {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .row2 {
    margin-left: 34px;
  }
  .row3 {
    margin-left: 34px;
  }
  .row4 {
    margin-left: 34px;
  }
}
.row3 {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}
.row4 {
  display: flex;
  flex-direction: column;
  margin-right: 29px;
}

.contact_detail {
  font-size: 14px;
  font-weight: 600;
  font-family: "Roboto" sans-serif;
}

.handles {
  padding: 25px 0;
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  width: 100%;
  background: #1c2329;
  padding: 35px 0px 30px 0px;
  color: #8e9194;
  font-size: 13px;
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }
}

.first_half {
  margin-left: 50px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

.first_half > a {
  padding: 0 5px;
  text-decoration: none;
  color: #8e9194;
}

.second_half {
  margin-right: 50px;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.second_half > a {
  padding: 0 5px;
  text-decoration: none;
  color: #8e9194;
}

.option_link {
  color: #fff;
}
.footer h4 {
  color: #fff;
}
.disabledcursor {
  cursor: default;
}
