:root {
  --color-white: #ffffff;
  --color-white-rgb: 255, 255, 255;
  --color-primary: #3381cf;
  --color-default: #1a1f24;
  --color-default-rgb: 26, 31, 36;
}
.comp_ser .button {
  background: #515151;
  padding: 7px 19px;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 22px;
  font-family: "Roboto", sans-serif;
  border: none;
  border-radius: 7px;
}

.comp_ser .button-2 {
  color: #fff;
  border: 1px solid #727272;
  background-image: linear-gradient(30deg, #7e7e7e, 50%, transparent 50%);
  background-size: 260px;
  background-repeat: no-repeat;
  background-position: 0%;
  transition: background 700ms ease-in-out;
}
.comp_ser .button-2:hover {
  background-position: 100%;
  color: #ffffff;
}
.para_serv {
  font-size: 14px;
  text-align: center;
  margin-bottom: 33px;
}
.heads-title h1 {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: "Roboto" sans-serif;
  font-size: 31px;
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
.comp_ser {
  margin-top: -63px;
  padding: 90px;
  background: rgb(245, 245, 245);
  margin-bottom: -30px;
}
.comp_ser .img {
  border-radius: 8px;
  overflow: hidden;
}

.comp_ser .img img {
  transition: 0.6s;
}

.comp_ser .home-details {
  padding: 40px 30px;
  margin: -100px 30px 0 30px;
  transition: all ease-in-out 0.3s;
  background: var(--color-white);
  position: relative;
  background: rgba(var(--color-white-rgb), 0.9);
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 0 25px rgba(var(--color-black-rgb), 0.1);
  height: 305px;
}

.comp_ser .home-details .icon {
  margin: 0;
  width: 62px;
  height: 62px;
  background: var(--color-primary);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 28px;
  transition: ease-in-out 0.3s;
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  border: 2px solid var(--color-white);
}

.comp_ser .home-details h3 {
  color: var(--color-default);
  font-weight: 700;
  margin: 10px 0 15px 0;
  font-size: 20px;
  transition: ease-in-out 0.3s;
  text-decoration: none;
}

.comp_ser .home-details p {
  line-height: 24px;
  font-size: 14px;
  margin-top: 24px;
}

.comp_ser .home-service:hover .home-details h3 {
  color: var(--color-primary);
}
Link {
  text-decoration: none;
}

.comp_ser .home-service:hover .home-details .icon {
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
}

.comp_ser .home-service:hover .home-details .icon i {
  color: var(--color-white);
}

.comp_ser .home-service:hover .img img {
  transform: scale(1.2);
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  .heads-title h1 {
    font-size: 20px;
  }
  .comp_ser .home-details {
    padding: 23px 12px;
  }
  .comp_ser .button {
    margin-top: 12px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .heads-title h1 {
    font-size: 25px;
  }
  .comp_ser .home-details {
    padding: 27px 13px;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  .heads-title h1 {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .comp_ser {
    margin-top: 0px;
    padding: 8px;
    margin-bottom: 1px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .comp_ser {
    padding: 14px;
    margin-bottom: 37px;
    margin-top: 2px;
  }
  .comp_ser .img img {
    border-radius: 10px;
  }
  .comp_ser .home-details h3 {
    margin-bottom: 17px;
    font-size: 17px;
    margin-top: 14px;
  }
  .comp_ser .home-details p {
    line-height: 21px;
    font-size: 14px;
    margin-top: -7px;
    text-align: center;
  }
  .comp_ser .home-details {
    padding: 25px 10px;
    margin: -47px 9px 0 10px;
  }
}
