.technoService::before {
  content: "";
  position: absolute;
  background: #fdeff2;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.4s;
  z-index: -1;
}
.technoService:hover {
  transform: scale(1.1);
}
.technoService {
  box-shadow: 2px 0 35px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  width: 100%;
  align-items: center;
  margin: 20px 20px;
  padding: 38px 30px;
  background-color: #004055;
  border-radius: 10px;
  transition: transform 0.5s;
  @media (max-width: 768px) {
    margin: 15px auto;
  }
}
.technoService:hover {
  border-radius: 10px;
  box-shadow: 0 10px 15px 0 #0000002f, 0 1px 6px #0000000a;
  border: 0;
  transition: all 0.15s ease-in;
  top: 0;
}
.tech_title_1 {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-family: "Roboto" sans-serif;
  font-weight: 500;
  text-decoration: none;
  color: #01bf8c;
  margin-bottom: 10px;
}
.description_tech {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: rgb(254 252 252);
}
.techno_component {
  margin-top: 2px;
}
.tech_images {
  margin-left: auto;
  margin-right: auto;
  width: 114px;
  margin-bottom: 0;
}
.tech_images:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .technoService {
    padding: 11px 6px;
  }
  .techno_component {
    margin-top: 15px;
  }
}
