.home-faq .content h3 {
  font-weight: 400;
  font-size: 34px;
}
.home-img {
  margin-left: -147px;
}
.home-faq .content p {
  font-size: 15px;
  color: rgba(var(--default-color-rgb), 0.7);
}
.home-faq .faq-container {
  margin-top: 29px;
}
.home-faq .faq-container .faq-item {
  position: relative;
  padding: 15px;
  margin-bottom: 17px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-left: -130px;
}

.home-faq .faq-container .faq-item:last-child {
  margin-bottom: 0;
}
.home-faq h1 {
  margin-top: 45px;
  margin-bottom: 20px;
  font-family: "Roboto" sans-serif;
  font-size: 31px;
  font-weight: 700;
}
.home-faq .faq-container .faq-item h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0 30px 0 0;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.home-faq .faq-container .faq-item h3 .num {
  color: #2563eb;
  padding-right: 5px;
}

.home-faq .faq-container .faq-item h3:hover {
  color: #2563eb;
}

.home-faq .faq-container .faq-item .faq-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.home-faq .faq-container .faq-item .faq-content p {
  margin-bottom: 0;
  overflow: hidden;
  text-align: left;
}

.home-faq .faq-container .faq-item .faq-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 0;
  transition: 0.3s;
  cursor: pointer;
}

.home-faq .faq-container .faq-item .faq-toggle:hover {
  color: #2563eb;
}

.home-faq .faq-container .faq-active h3 {
  color: #2563eb;
}

.home-faq .faq-container .faq-active .faq-content {
  grid-template-rows: 1fr;
  visibility: visible;
  opacity: 1;
  padding-top: 10px;
}

.home-faq .faq-container .faq-active .faq-toggle {
  transform: rotate(90deg);
  color: #2563eb;
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  .home-faq h1 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .home-faq h1 {
    font-size: 25px;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  .home-faq h1 {
    margin-top: 20px;
    font-size: 26px;
  }
}
@media screen and (max-width: 767px) {
  .home-img {
    margin-left: 0px;
  }
  .home-faq .faq-container .faq-item {
    margin-left: 0px;
  }
  .home-faq .faq-container .faq-item h3 {
    font-size: 15px;
    line-height: 18px;
  }
  .home-faq .faq-container .faq-item .faq-content p {
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .home-img {
    margin-left: -295px;
  }
  .home-faq .faq-container .faq-item {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .home-img {
    margin-left: -165px;
  }
}
