:root {
  --background-color: #ffffff;
  --default-color: #444444;
  --heading-color: #222222;
  --accent-color: #106eea;
  --contrast-color: #ffffff;
}
.carousel_comp {
  color: var(--default-color);
  background-color: #023d69;
  padding: 39px 0;
  scroll-margin-top: 118px;
  overflow: clip;
  margin-top: 30px;
}
.carousel_comp .carousel_comp-content h3 {
  font-weight: 700;
  font-size: 26px;
}
.carousel_comp h2 {
  font-size: 27px;
  margin-bottom: 42px;
  margin-top: -17px;
  color: #ffffff;
}
.carousel_comp .carousel_comp-content ul {
  list-style: none;
  padding: 0;
}

.carousel_comp .carousel_comp-content ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
}

.carousel_comp .carousel_comp-content ul li:first-child {
  margin-top: 35px;
}

.carousel_comp .carousel_comp-content ul i {
  background: var(--contrast-color);
  box-shadow: 0px 6px 15px
    color-mix(in srgb, var(--accent-color), transparent 90%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  font-size: 24px;
  margin-right: 15px;
  color: var(--accent-color);
  border-radius: 50px;
}

.carousel_comp .carousel_comp-content ul h4 {
  font-size: 18px;
  font-weight: 600;
}

.carousel_comp .carousel_comp-content ul p {
  font-size: 15px;
}

.carousel_comp .carousel_comp-content p:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .carousel_comp {
    margin-top: 0px;
  }
  .carousel-indicators {
    bottom: -33px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .carousel-indicators {
    bottom: -26px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .carousel-indicators {
    bottom: -32px !important;
  }
}
