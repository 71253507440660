.popUp {
  top: 98%;
  left: -29%;
  color: #ffffff;
  background: #0b6bbf;
  font-family: inherit;
  transform: translate(280%, -120%);
  padding: 0px 15px;
  align-items: center;
  line-height: 2.5em;
  position: fixed;
  overflow: hidden;
  transition: color 0.5s;
  z-index: 1000;
  font-size: 15px;
  border-radius: 5px;
  font-weight: 600;
}
.popUp:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: #3a93eb;
  height: 150px;
  width: 200px;
  border-radius: 50%;
}
.popUp:hover {
  color: #fff;
}
.popUp:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}
.popUp:hover:before {
  top: -30px;
  left: -30px;
}
/* @media only screen and (min-width: 320px) and (max-width: 375px) {
  .popUp {
    left: -117%;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .popUp {
    left: -100%;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  .popUp {
    left: -88%;
  }
} */

@media (max-width: 767px) {
  .popUp {
    left: -93%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .popUp {
    left: -46%;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .popUp {
    left: -25%;
  }
}
